<template>
    <div class="mx-5 sm:mx-16 md:mx-20 text-white sm:text-lg justify-items-center grid grid-cols-1 text-center gap-5 sm:gap-7">
        <img 
            src="../assets/images/exclusives/text-image.jpg"
            class="rounded-lg shadow-xl mt-6 sm:mt-8 h-auto w-96"
        />
        <p>Extraordinary handcrafted sculptures with beautiful gold plated finishing and precious stones such as malachite, lapis, amethyst and agate</p>
        <p>Each piece showcases the typical Italian blend of tradition, quality and innovation.</p>
        <p>Any sculpture can be created and customized, even in its real life dimensions</p>
        <p> After the creation of the sculpture with a marble-powder paste, the piece is silver-coated through a galvanic process and then hand-finished with gold details and personalized finishing, such as burnishing, in order to highlight all the details.</p>
        <p> A further phase of production is the coating of the materials with an antioxidants.
             This process is carried out to preserve and protect the object over time in order to provide a work of art of the highest quality.
        </p>
        <img 
            src="../assets/images/exclusives/about-image.jpg"
            class="rounded-lg shadow-xl h-auto lg:w-2/3"
        />
    </div>
</template>